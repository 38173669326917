"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";

const CanonicalLink = () => {
  const [canonicalUrl, setCanonicalUrl] = useState("");
  const pathname = usePathname();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const baseUrl = "https://www.xchess.ai";
      setCanonicalUrl(`${baseUrl}${pathname}`);
    }
  }, [pathname]);

  return <link rel="canonical" href={canonicalUrl} />;
};

export default CanonicalLink;
